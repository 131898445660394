.home{
    background-color: #000000;
    background-image: 
        radial-gradient(at 47% 33%, rgb(3, 39, 97) 0, transparent 59%), 
        radial-gradient(at 82% 65%, rgb(1, 38, 100) 0, transparent 55%);

}
.homepage{
    min-height: 100vh;
    min-width: 100%;
}

.landing-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://images.unsplash.com/photo-1708649290066-5f617003b93f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8NnxCb3diRkFscTcxc3x8ZW58MHx8fHx8");
    background-repeat: no-repeat;
    opacity: 70%;
    background-size: cover;
  }

  .video-background__inner {
    /* Positioned at the top left corner */
    left: 0px;
    position: absolute;
    top: 0px;

    /* Take full size */
    height: 100%;
    width: 100%;

    /* Hide the scrollbar */
    overflow: hidden;
}

.video-background__video {
    object-fit: cover;

    /* Take full width */
    height: 100%;
    max-width: 100%;
}
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.377); 
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Orbitron', sans-serif;

  }
  
/*standardize achievement sections*/
div.cards-container img.standardized-image{
  width: 100%;
  height: 30vh;
  display:block;

}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  font-size: 90px;
  font-weight: bold;
  transition: text-shadow 0.3s ease, opacity 0.3s ease;
  font-family: 'Orbitron', sans-serif;

}

.tagline {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: #fff;
  font-size: 18px;
  text-align: center;
  transition: opacity 0.3s ease;
}

.overlay:hover .text {
  opacity: 0.8;
}

.overlay:hover .tagline {
  opacity: 0.8;
}


  
  .company-section {
    padding: 40px;
    margin: 20px 20px;
    text-align: center;
    color: #fff;
  }
  
  .company-section h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  .company-section p {
    font-size: 20px;
    line-height: 1.5;
    margin: 50px;
    /* padding: 40px  ; */
  }  

  /* ... Previous CSS styles ... */

.services-section {
    color:#fff;
    padding: 40px;
    text-align: center;
  }
  
  .services-section h2 {
    font-size: 30   px;
    margin-bottom: 40px;
  }
  
  .services-container {
    display: flex;
    justify-content: center;    
    padding: 10px;
  }
  
  .service {
    width: 300px;
    padding: 20px;
    margin: 0 20px;

    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }
  
  .service h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .service p {
    font-size: 14px;
    line-height: 1.5;
  }
    

  .achievement-cards {
    max-width: 80%;
    margin: 0 auto;

    
  }
  
  .achievement-cards h2 {
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
  }
  
  
  .card {
    width: calc(33.33% - 20px); /* Adjust the width as needed */
    margin-bottom: 20px;
    overflow: hidden;
    color:#fff;

    backdrop-filter: blur(16px) saturate(200%);
    -webkit-backdrop-filter: blur(16px) saturate(200%);
    background-color: rgba(11, 19, 31, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    
  }
  
  .card img {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 10px;
  }
  
  .card-content h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .card-content p {
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Additional styles can be added for hover effects, transitions, etc. */
  .carousel-gallery {
    max-width: 80%;
    margin: 0 auto;
    
  }
  
  .carousel-gallery h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .gallery-card {
    text-align: center;
  }
  
  .gallery-card img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .gallery-card h3 {
    font-size: 16px;
    margin: 0;
  }
  
    

  .contact-us {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10%;
    color: #fff;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 50px;
  }
  
  .company-details {
    flex: 1;
  }
  
  .contact-form {
    flex: 1;

  }
  
  /*.contact-form form {
}*/
  
  .contact-form .form-group {
    margin-bottom: 10px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 5px;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  
  footer {
    background-color: #222;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  
  .left-section {
    text-align: left;
  }
  
  .right-section a {
    color: #fff;
    margin-left: 10px;
    text-decoration: none;
    display: block;
  }
  
  .right-section a:hover {
    text-decoration: none;
  }
  
  .right-section a:first-child {
    margin-left: 0;
  }
  
  .right-section a:last-child {
    margin-right: 0;
  }
  
  .footer-content p {
    margin: 5px 0;
  }
  
  .copyright {
    margin-top: 20px;
  }
  
  .customer-logos {
    text-align: center;
    padding: 20px;
  }
  
  .customer-logos h3 {
    margin-bottom: 30px;
    color: #fff;
    font-size: 30px;
  }
  
  .logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
    padding: 30px;
  }
  
  .logo-item img {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
  }

  

  /* =============================================== */


/* For screens with a maximum width of 767px (mobile devices) */
@media (max-width: 767px) {

  .homepage {
    min-width: 100%;
  }
  .landing-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("https://images.unsplash.com/photo-1647427060118-4911c9821b82?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"); */
    background-repeat: no-repeat;
    opacity: 70%;
     background-size: cover;
  }
  .text {
    font-size: 40px;
  }

  .tagline {
    font-size: 14px;
  }

  .company-section h2 {
    font-size: 24px;
  }

  .company-section p {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  .service {
    width: 100%;
    margin: 10px;
  }

  .service h3 {
    font-size: 16px;
  }

  .service p {
    font-size: 12px;
  }
  
  .services-container {
    display: block;
    justify-content: center;    
    padding: 10px;
    margin-right: 30px;
  }
  .card {
    width: 100%;
    margin-bottom: 10px;
  }

  .card-content h3 {
    font-size: 14px;
  }

  .card-content p {
    font-size: 12px;
  }

  .gallery-card {
    text-align: center;
    width: 100%;
  }

  .gallery-card img {
    max-width: 100%;
  }

  .gallery-card h3 {
    font-size: 14px;
  }

  .contact-us {
    flex-direction: column;
    margin: 5%;
    padding: 20px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 5px;
  }

  .footer-content {
    flex-direction: column;
  }

  .logo-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo-item img {
    max-height: 80px;
  }

  /* Add more responsive styles for mobile devices here */
}

/* For screens with a minimum width of 768px and a maximum width of 1199px (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
  /* Adjust styles for tablets here */
  /* For example, modify container widths, font sizes, margins, etc. */
  .homepage {
    min-width: 10rem;
  }

  .text {
    font-size: 60px;
  }

  .tagline {
    font-size: 16px;
  }

  .company-section h2 {
    font-size: 28px;
  }

  .company-section p {
    font-size: 18px;
    margin: 40px;
    padding: 30px;
  }

  .service {
    width: 50%;
    margin: 20px;
  }

  .service h3 {
    font-size: 18px;
  }

  .service p {
    font-size: 14px;
  }

  .card {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }

  .card-content h3 {
    font-size: 16px;
  }

  .card-content p {
    font-size: 14px;
  }

  .gallery-card {
    text-align: center;
    width: 50%;
  }

  .gallery-card img {
    max-width: 80%;
  }

  .gallery-card h3 {
    font-size: 16px;
  }

  .contact-us {
    flex-direction: row;
    margin: 5%;
    padding: 50px;
  }

  .contact-form {
    width: 50%;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
  }

  .footer-content {
    flex-direction: row;
  }

  .logo-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .logo-item img {
    max-height: 100px;
  }

  /* Add more responsive styles for tablets here */
}

/* For screens with a minimum width of 1200px (laptops and larger screens) */
@media (min-width: 1200px) {
  /* Adjust styles for laptops and larger screens here */
  /* For example, modify container widths, font sizes, margins, etc. */
  .homepage {
    min-width: 12rem;
  }

  .text {
    font-size: 90px;
  }

  .tagline {
    font-size: 18px;
  }

  .company-section h2 {
    font-size: 30px;
  }

  .company-section p {
    font-size: 20px;
    margin: 50px;
    padding: 40px;
  }

  .service {
    width: 33.33%;
    margin: 0 20px;
  }

  .service h3 {
    font-size: 18px;
  }

  .service p {
    font-size: 14px;
  }

  .card {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
  }

  .card-content h3 {
    font-size: 16px;
  }

  .card-content p {
    font-size: 14px;
  }

  .gallery-card {
    text-align: center;
    width: 33.33%;
  }

  .gallery-card img {
    max-width: 100%;
  }

  .gallery-card h3 {
    font-size: 16px;
  }

  .contact-us {
    flex-direction: row;
    margin: 10%;
    padding: 50px;
  }

  .contact-form {
    width: 50%;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
  }

  .footer-content {
    flex-direction: row;
  }

  .logo-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .logo-item img {
    max-height: 100px;
  }

  /* Add more responsive styles for laptops and larger screens here */
}
