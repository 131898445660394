
.training{
    background-color: black;
    color: #fff;
    height: 100vh;

}
.training-plans {
    padding: 20px;
    align-items: center;
    align-content: centers;
    
  }
  
  .training-plans h1 {
    text-align: center;
    margin-top: 60px;
  }
  
  .plans-container {
    display: flex;
    justify-content: center;

  }
  
  .plan-card {
    width: 300px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    backdrop-filter: blur(12px) saturate(64%);
    -webkit-backdrop-filter: blur(12px) saturate(64%);
    background-color: rgba(17, 25, 40, 0.36);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
  }
  
  .plan-card img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .plan-card h2 {
    margin-bottom: 10px;
  }
  
  .plan-card h3 {
    margin-bottom: 20px;
  }
  
  .plan-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-card li {
    margin-bottom: 5px;
  }
  
  .plan-card button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .plan-card button:hover {
    background-color: #0056b3;
  }
  
  .plan-card button:focus {
    outline: none;
  }
  


  .contact-form {
    flex: 1;
    max-width: 50%;

  }
  
  .contact-form form {
    /* Form styles go here */
  }
  
  .contact-form .form-group {
    margin-bottom: 10px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 5px;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  