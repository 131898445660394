.navbar {
  background-color: #000;
  color: #fff;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  padding: 0 20px;
}

.navbar-logo img {
  height: 50px;
  margin-left: 0;
}

.navbar-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-item {
  margin: 0 50px;
}

.navbar-link {
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
}

.navbar-link:hover {
  color: #486aff;
}

.navbar-toggle {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;  
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #333;
  }

  .navbar-menu.active {
    max-height: 200px;
  }

  .navbar-item {
    margin: 5px 0;
  }

  .navbar-toggle {
    display: block;
  }
}


@media (max-width: 767px) {
  .navbar {
    background-color: #000;
    color: #fff;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
  }
  .navbar-logo img {
    height: 40px;
    margin-left: 0;
  }
}