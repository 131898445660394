.gallery {
    padding: 10px;
    background-color: black;
    height: 100%;
  }
  
  .gallery h1 {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .gallery-grid {
    display: flex;
    margin-left: -15px;
    width: auto;
  }
  
  .gallery-grid-column {
    padding-left: 15px;
    background-clip: padding-box;
  }
  
  .gallery-item {
    margin-bottom: 10px;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .gallery-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  


  @media (max-width: 768px) {
    .gallery-grid {
      margin-left: 0;
    }
  }
  