.error-page {
    background-color: black;
    color: white;
    text-align: center;
    height: 100vh;
    align-items: center;
    align-content: center;
    padding-top: 200px;

}
  
  .error-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .error-page p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  
  .error-page img {
    display: block;
    margin: auto;
    max-width: 300px;
  }
  