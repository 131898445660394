.team {
  background-color: #000;
  color: #fff;

}

.team-page {
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

h1 {
  margin-top: 50px;
}

.team-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: fit-content;
}



.team-card__img {
  visibility: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

}

.team-card__img--hover {
  transition: 0.2s all ease-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;

}

.team-card {
  display: inline-block;
  margin: 25px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  width: 288px;
  height: fit-content;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.team-card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.10, 1.10);
}

.team-card__info {
  z-index: 2;
  background-color: rgba(17, 25, 40, 0.75);
  min-height: 90px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 24px 24px 24px;
}

.team-card__category {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: lightgray;
}

.team-card__title {
  margin-top: 5px;
  margin-bottom: 10px;
  color: white;
}

.team-card:hover .team-card__img--hover {
  height: 100%;
  opacity: 0.3;
}

.skills {
  margin-top: 10px;
}

.skill-tag {
  display: inline-block;
  background-color: #ff01018a;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 2px
}


.team-card:hover .social-link a {
  bottom: 50%;
  opacity: 1;
}

.social-link a {
  position: absolute;
  bottom: -120px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  text-decoration: none;
  color: #fff;
  margin: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: black;
}

.social-link a:nth-child(1) {
  left: 30%;
  transition: 0.5s;
}

.social-link a:nth-child(2) {
  left: 48%;
  transition: 0.65s;
}

.social-link a:nth-child(3) {
  left: 66%;
  transition: 0.8s;
}